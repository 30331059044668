import React, { Component } from 'react';
import 'antd/dist/antd.css';
import './App.css';
import { initializeApp } from 'firebase/app';
import {getAuth} from 'firebase/auth';
import 'firebase/auth';
import GlobalStore from './store/GlobalStore';
import RouterModule from './Router';
import ErrorBoundary from './components/common/ErrorBoundary';
import AccountStore from './store/AccountStore';

class App extends Component {
  
 constructor(props){
    super(props);
    this.state = {
      isLoggedIn : true,
      loginChecking:true
    }
    
    var config = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_DOMAIN,
    };
   
    initializeApp(config);
    if(process.env.NODE_ENV === 'development'){
      // LogRocket.init('fkk2vj/goodflow');
      // setupLogRocketReact(LogRocket);
    }
  }

  componentDidMount(){
    var self = this;
    GlobalStore.fetchToolTips();
    const auth = getAuth();  
    auth.onAuthStateChanged(function(user) {
      if (user) {
        GlobalStore.userInformation = {name:user.displayName,id:user.uid,email:user.email}
        GlobalStore.isLoggedIn = true;
        GlobalStore.isEmailVerified = user.emailVerified;
        self.setState({isLoggedIn:true});
        AccountStore.fetchProfile()
      }
      else{
        self.setState({isLoggedIn:false})
        GlobalStore.isLoggedIn = false;
      }
    });
  

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches){
        document.getElementById("favicon").setAttribute("href","favicon-white.png")
    }
  }
  

  render() {
    return (
      <ErrorBoundary>
        <RouterModule />
      </ErrorBoundary> 
    );
  }
}

export default App;
