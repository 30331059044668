import React, { Component } from 'react';
import { Menu, Dropdown, Icon  } from 'antd';
import {Link } from 'react-router-dom';
import GlobalStore from '../../store/GlobalStore';
import {observer} from 'mobx-react';
import User from './User';
import AccountStore from '../../store/AccountStore';


const menu = () => (
    <Menu>
      <Menu.Item disabled={true}>
       <h4>{AccountStore.profileData.name}</h4>
       <p>{AccountStore.profileData.email}</p>
      </Menu.Item>
      <Menu.Item>
        <a href={`/account/profile`} rel='noopener noreferrer' target="_blank">Account Settings</a>
      </Menu.Item>
      <Menu.Item>
        <Link to="/logout"><Icon type="poweroff" /> Sign out</Link>
      </Menu.Item>
      
    </Menu>

  );

@observer
class AccountMenu extends Component {
    constructor(props){
      super(props);
      this.state={
        show: true,
      }
    }
    


    render() {
        return (
            <div className="account-block">
              <Dropdown overlay={menu(GlobalStore.workspace_name)} placement="bottomLeft">
                  {/* <div className="user-profile" style={{background:`#EAEAEA url(${GlobalStore.userInformation.profile_photo})`}}></div> */}
                  <div className="user-profile">
                    <User tooltipVisible={false} profilePicture={AccountStore.profileData.profile_photo} id={AccountStore.profileData.id} name={AccountStore.profileData.name} nameVisible={false} ppRound={false} emailVisible={false} ppWidth="30px" ppHeight="30px"/>
                  </div>
              </Dropdown>
            </div>
        );
    }
}

export default AccountMenu;