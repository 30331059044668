const scopes = [
    {
        id:'allow_automation_perform_create',
        label:'Allow creating perform through automation'
    },
    {
        id:'allow_perform_list',
        label:'Allow list of all performs'
    }
]

const reactions = [
   
    {
        id:"like",
        icon:"like.png",
        name:"Liked",
        color:"yellow"
    },
    {
        id:"heart",
        icon:"emoli-heart.png",
        name:"Loved",
        color:"red"
    },
    {
        id:"laugh",
        icon:"smiling.png",
        name:"Haha",
        color:"yellow"
    },
    {
        id:"sad",
        icon:"sad.png",
        name:"Sad",
        color:"orange"
    },
    {
        id:"angry",
        icon:"angry.png",
        name:"Angry",
        color:"brick"
    }
];

const icons = [
    {
        icon: "alert",
    },
    {
        icon: "appstore",
    },
    {
        icon: "bank",
    },
    {
        icon: "book",
    },
    {
        icon: "bug",
    },
    {
        icon: "bulb",
    },
    {
        icon: "calculator",
    },
    {
        icon: "calendar",
    },
    {
        icon: "camera",
    },
    {
        icon: "cloud",
    },
    {
        icon: "code",
    },
    {
        icon: "contacts",
    },
    {
        icon: "container",
    },
    {
        icon: "control",
    },
    {
        icon: "credit-card",
    },
    {
        icon: "dashboard",
    },
    {
        icon: "environment",
    },
    {
        icon: "experiment",
    },
    {
        icon: "fire",
    },
    {
        icon: "gift",
    },
    {
        icon: "idcard",
    },
    {
        icon: "interaction",
    },
    {
        icon: "rocket",
    },
    {
        icon: "read",
    },
    {
        icon: "team",
    },
    {
        icon: "qrcode",
    },
    {
        icon: "number",
    },
    {
        icon: "global",
    },
    {
        icon: "tool",
    },
];

const roles = [
    {
        key:"performer",
        label:"Performer"
    },
    {
        key:"viewer",
        label:"Performer+Viewer"
    },
    {
        key:"admin",
        label:"Admin"
    }
]

const viewTypes = ['chart','table'];


const activites = [
    {
        type:"checked",
        icon:"check"
    },
    {
        type:"change",
        icon:"edit"
    },
    {
        type:"upload",
        icon:"upload"
    },
    {
        type:"prompt",
        icon:"notification"
    },
    {
        type:"reopened",
        icon:"undo"
    },
    {
        type:"addUser",
        icon:"user-add"
    },
    {
        type:"created",
        icon:"rocket"
    },
    {
        type:"removeUser",
        icon:"user-delete"
    },
    {
        type:"webhook_trigger",
        icon:"cloud-upload"
    },
    {
        type:"email_trigger",
        icon:"mail"
    },
    {
        type:"stageMove",
        icon:"arrow-right"
    },
    {
        type:"create_perform",
        icon:"plus"
    }
];

const automationOrigins = [
    {
        id: "perform_start",
        heading: "On perform start",
        description: "Based on given condition, this automation will run as soon as the perform starts",
        icon:"rocket"
    },
    {
        id: "field_change",
        heading: "On changing a field value",
        description: "Based on given condition, this automation will when you change value of a selected field",
        optionLabel: "Select Field",
        optionErr: "Please select a field",
        dataType:"fields",
        icon:"edit"
    },
    {
        id: "stage_from",
        heading: "On moving from a stage",
        description: "Based on given condition, this automation will run when you move from a specific stage",
        optionErr: "Please select a stage",
        optionLabel: "Select stage",
        dataType:"stages",
        icon:"arrow-left"
    },
    {
        id: "stage_move",
        heading: "On moving to a stage",
        description: "Based on given condition, this automation will run when you move to a specific stage",
        optionErr: "Please select a stage",
        optionLabel: "Select stage",
        dataType:"stages",
        icon:"arrow-right"
    },
    {
        id: "task_complete",
        heading: "On marking a task as done",
        description: "Based on given condition, this automation will run as soon as a speicified task will be marked as done",
        optionLabel: "Select task",
        optionErr: "Please select a task",
        dataType:"tasks",
        icon:"check"
    },
    {
        id: "task_reopen",
        heading: "On re-opening a task",
        description: "Based on given condition, this automation will run as soon as a specified task will be re-opened",
        optionLabel: "Select task",
        optionErr: "Please select a task",
        dataType:"tasks",
        icon:"undo"
    },
    {
        id: "task_deadline",
        heading: "On missing deadline of a task",
        description: "Based on given condition, this automation will when a deadline of a task is missed",
        optionLabel: "Select task",
        optionErr: "Please select a task",
        dataType:"tasks",
        icon:"close-circle"
    },
    {
        id: "stage_deadline",
        heading: "On missing estimated deadline os a stage",
        description: "Based on given condition, this automation will when  a estimated deadline for stage is missed",
        optionLabel: "Select task",
        optionErr: "Please select a task",
        dataType:"stages",
        icon:"close-circle"
    }
];

const automationEffects = [
    {
        id:"email",
        label:"Send email automatically"
    },
    {
        id:"webhook",
        label:"Send a webhook request"
    },
    {
        id:"move_to_stage",
        label:"Move to another stage"
    },
    {
        id:"send_notification",
        label:"Send notification"
    },
    {
        id:"create_perform",
        label:"Create a perform in another Workflow"
    }
]

const defaultColumns = [
    {
        label:"Current Stage",
        id:"current_stage"
    },
    {
        label:"Created on",
        id:"created_on"
    },
    {
        label:"Updated on",
        id:"updated_on"
    },
    {
        label:"Created by",
        id:"created_by"
    },
    {
        label:"Members",
        id:"members"
    },
    {
        label:"Last Activity",
        id:"last_activity"
    }
]


const workspaceAdminPermissions = [
    {
        name:"Workspace Profile",
        key:"profile"
    },
    {
        name:"Members Management",
        key:"members",
        permissions:['Add/Edit','Delete','Import']
    },
    {
        name:"Designations Management",
        key:"designations",
        permissions:['Add/Edit','Delete']
    },
    {
        name:"Workflows Management",
        key:"workflows",
        permissions:['List','Add','Deactivate','Delete']
    },
    {
        name:"Email Settings",
        key:"email",
        permissions:['List','Add/Edit','Delete']
    },
    {
        name:"API Tokens",
        key:"api",
        permissions:['Add/Edit','Delete']
    },
    {
        name:"Billing Management",
        key:"billing",
        permissions:['Change Plan','History','Cards Management']
    }
]

const teamSizes = [
    {
        label:'1-5'
    },
    {
        label:'6-10'
    },
    {
        label:'11-25'
    },
    {
        label:'26-50'
    },
    {
        label:'51-100'
    },
    {
        label:'101-200'
    },
    {
        label:'Over 200',
        value:'>200'
    }
]

const TwoFAMethods = [
    {
        name: "Microsoft Authenticator",
        value: "microsoft",
        available: true
    },
    {
        name: "Google Authenticator",
        value: "google",
        available: true
    },
    {
        name: "SMS",
        value: "sms",
        available: true
    },
    {
        name: "Email",
        value: "email",
        available: true
    }
]

const genders = [
    {
        label:"Male",
        value:"male"
    },
    {
        label:"Female",
        value:"female"
    },
    {
        label:"Non-binary",
        value:"non-binary"
    },
    {
        label:"Transgender",
        value:"transgender"
    },
    {
        label:"Intersex",
        value:"intersex"
    },
    {
        label:"I'd prefer not to say",
        value:"others"
    }
]

const allowedDateFormats = [
    'DD-MM-YYYY',
    'MM-DD-YYYY',
    'YYYY-MM-DD',
    'DD-MMM-YYYY',
    'MMM DD,YYYY',
    'DD/MM/YYYY',
    'YYYY/MM/DD'
]

const allowedTimeFormats = [
    'HH:mm',
    'hh:mm A',
    'hh:mm:ss',
    'HH:mm:ss A'
]

const additionalTimezonesLimit = 3;

export default {scopes,reactions,icons,roles,viewTypes, activites,automationOrigins,automationEffects,defaultColumns,workspaceAdminPermissions,teamSizes, TwoFAMethods, genders, allowedDateFormats, allowedTimeFormats,additionalTimezonesLimit}
