import {getAuth,onAuthStateChanged} from "firebase/auth";


class FirebaseService {
    getFirebaseAccessToken(){

        return new Promise((resolve,reject)=>
            {
                const auth = getAuth();
                onAuthStateChanged(auth,function(user) {
                    if (user) {
                        user.getIdToken()
                        .then(function(idToken) {
                            resolve(idToken) ;
                        }).catch(function(error) {
                            reject()
                        })
                    }
                });
            
            }
           
        )
    }

    

}

export default new FirebaseService();