import { observable } from 'mobx';
import Httpservice from '../service/HttpService';
import FirebaseService from '../service/FirebaseService';
import { message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import HttpService from '../service/HttpService';
import Fields from '../assets/json/fields.json';
import ContentFields from '../assets/json/contents.json';

class GlobalStore {
    @observable checkList = { id: '', name: '', settings: { performButtonName: "PERFORM" }, notifications: { onPerformCreate: [] } };
    @observable envoirnment = process.env.NODE_ENV;
   // @observable siteUrl = this.envoirnment === 'development' ? 'testurl.test:3000' : this.envoirnment === 'staging' ? 'checksure.xyz' : 'goodflow.io';
    @observable secureLayer = this.envoirnment === 'development' ? ('http://') : ('https://');
    @observable isHttps = this.envoirnment === 'development' ? false : this.envoirnment === 'staging' ? false : true;
    @observable checkList = { id: '', name: '' };
    @observable history = '';
    @observable isLoggedIn = true;
    @observable isEmailVerified = true;
    @observable taskOrder = [];
    @observable userInformation = {}
    @observable isLoading = false;
    @observable themeColor = "#15507C";
    @observable pageLoading = true;
    @observable companyUn = "";
   // @observable mainUrl = "http://ff.testurl.test:3000/workflows";
    @observable subToken = "";
    @observable fileUploadPercentage = 0;
    @observable tooltipsData = [];
    @observable user_type = "";
    @observable workspace_name = "";
    @observable workspaceBranchEnabled = false;
    @observable workspaceBranches = [];
    @observable userContentUrl = `https://s3.${process.env.REACT_APP_AWS_S3_REGION}.amazonaws.com/${process.env.REACT_APP_AWS_S3_BUCKET}/`;
    @observable reservedSubdomains = ['app', 'help', 'knowledege', 'web', 'goodflow', 'admin'];
    @observable secondMenuCollpased = true;
    @observable prismic_access_token = 'MC5YSW45RHhFQUFQN05DVzFC.77-9WO-_vTzvv73vv73vv70BNO-_ve-_ve-_ve-_ve-_ve-_vVzvv73vv71u77-977-9JiXvv71h77-9NzQBHhgH';
    @observable prismic_endpoint = 'https://goodflow.cdn.prismic.io/api/v2';
    @observable workspaceBranchList = [];
    @observable isWorkflowsVisible = false;
    @observable isWorkspaceOnTrial = false;
    @observable isWorkspacePactive = false;
    @observable trialEndsOn = null;
    @observable isTrialOver = false;
    @observable isSidebarVisible = false;
    @observable sidebarActiveComponent = "";
    @observable workspace_uid = "";
    @observable socket = null;
    @observable rolePermissions = null;
    @observable is2FA = false;
    @observable workspaceLogo = "";
    @observable workspaceId = "";

    getSiteURL = (workspaceName="app") => {
        let uri = process.env.NODE_ENV === 'development'?'localhost:3000':process.env.NODE_ENV==='staging'?'checksure.xyz':process.env.NODE_ENV==='production'?'goodflow.io':'';
        return `${this.secureLayer}${workspaceName}.${uri}`;
    }

    getAPIDomain = () => {
        const envoirnment = process.env.NODE_ENV;
        let api_url = envoirnment==='development'?`http://localhost:5200`:envoirnment==='staging'?'http://apis.checksure.xyz':'https://apis-v1.goodflow.io';
        return api_url;
    }

    setTitle(pageName, isGoodFlowBranding = true) {
        document.title = `${pageName} ${isGoodFlowBranding ? '- GoodFlow' : ''}`;
    }

    fetchToolTips = () => {
        axios.get(`${this.prismic_endpoint}?access_token=${this.prismic_access_token}`, 'dsds').then(fetchedData => {
            if (fetchedData.status === 200) {
                var refid = fetchedData.data.refs[0].ref;
                axios.get(`${this.prismic_endpoint}/documents/search?access_token=${this.prismic_access_token}&ref=${refid}&q=[[:d=at(document.type,"tooltip")]]`)
                    .then(res => {
                        if (res.status === 200) {
                            res.data.results.forEach(item => {
                                this.tooltipsData.push(Object.assign({}, { id: item.uid }, item.data));
                            })
                        }
                    }).catch(err => {
                    })
            }
        }).catch(err => {
        })
    }

    fetchToolTip = (id) => new Promise(resolve=>{
        axios.get(`${this.prismic_endpoint}?access_token=${this.prismic_access_token}`, 'dsds').then(fetchedData => {
            if (fetchedData.status === 200) {
                var refid = fetchedData.data.refs[0].ref;
                axios.get(`${this.prismic_endpoint}/documents/search?access_token=${this.prismic_access_token}&ref=${refid}&q=[[:d=at(my.tooltip.uid,"${id}")]]`)
                    .then(res => {
                        if (res.status === 200) {
                           if(res.data.results && res.data.results.length > 0){
                               resolve(res.data.results[0].data)
                           }
                        }
                    }).catch(err => {
                    })
            }
        }).catch(err => {
        })
    })

    getProfileImage = (fileName, type) => new Promise(resolve => {
        FirebaseService.getFirebaseAccessToken().then(token => {
            Httpservice.get('upload/filename/' + type + '/' + fileName, token).then(res => {
                if (res.data !== undefined) {
                    resolve(res.data.data)
                }

            })
        })
    })

    getUserProfile = () => new Promise((resolve,reject) => {
        FirebaseService.getFirebaseAccessToken().then(token => {
            Httpservice.get('user/profile', token).then(res => {
                if (res.status === 200) {
                    Object.assign(this.userInformation, res.data.result);
                    if (res.data.status === true) {
                        this.user_type = res.data.result.userType;
                        this.userInformation.profile_photo = res.data.result.profile_photo;
                        this.userInformation._id = res.data.result.id;
                        this.is2FA = res.data.result.is2FA;
                        resolve();
                    }
                    else {
                        message.error(res.data.message, 0.8);
                        reject()
                    }
                }
            }).catch(err => {
                reject()
            })
        });
    })

   

    fetchUserPermission() {
        FirebaseService.getFirebaseAccessToken().then(token => {
            Httpservice.get('workspace/company/member/user_type', token)
                .then(res => {
                    if (res.data.status) {
                        this.userInformation.user_type = res.data.result;
                        //setting user type of member
                    }
                    else {
                        message.error(res.data.message.toString());
                    }
                })
        })
    }


    createTempId = () => {
        var stringMaker = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
        var tempId = "";
        for (let i = 0; i < 6; i++) {
            var letter = stringMaker[Math.floor(Math.random() * stringMaker.length)];
            tempId = tempId + letter;
        }
        return tempId;
    }

    setTimeDetails(timestamp) {
        var date_time=moment(timestamp,'x').format('lll')
        var now = moment().format('lll');
        if (moment(date_time).fromNow().split(" ")[1] === 'hours' || moment(date_time).fromNow().split(" ")[1] === 'day' || moment(date_time).fromNow().split(" ")[1] === 'days') {
            if (parseInt(moment(date_time).fromNow().split(" ")[0]) > 12 || moment(date_time).fromNow().split(" ")[1] === 'day' || moment(date_time).fromNow().split(" ")[1] === 'days') {
                return date_time;
            }
            else {

                return moment(date_time).fromNow();
            }
        }
        else {
            if (moment(now).format("hh:mm") >= moment(date_time).format("hh:mm")) {

                return moment(date_time).fromNow();
            }
            else {
                return date_time;
            }
        }
    }


    getDateTime = (timestamp) => {
        var date_time = moment(timestamp).format('lll')
        var now = moment().format('lll');
        if (moment(date_time).fromNow().split(" ")[1] === 'hours' || moment(date_time).fromNow().split(" ")[1] === 'day' || moment(date_time).fromNow().split(" ")[1] === 'days') {
            if (parseInt(moment(date_time).fromNow().split(" ")[0]) > 12 || moment(date_time).fromNow().split(" ")[1] === 'day' || moment(date_time).fromNow().split(" ")[1] === 'days') {
                return date_time;
            }
            else {

                return moment(date_time).fromNow();
            }
        }
        else {
            if (moment(now).format("hh:mm") >= moment(date_time).format("hh:mm")) {

                return moment(date_time).fromNow();
            }
            else {
                return date_time;
            }
        }
    }

    getTempId = () => {
        let randomId = new Date().getTime();
        let CharArray = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
        for (let i = 0; i < 3; i++) {
            var CharObject = CharArray[Math.floor(Math.random() * CharArray.length)];
            randomId = `${CharObject}${randomId}`
        }
        return randomId;

    }

    searchMember = (params) => {
        FirebaseService.getFirebaseAccessToken().then(token => {
            HttpService.post('workspace/member/search', params, token).then(res => {
            })
        })
    }


    showSideBar = (component) =>{
        this.isSidebarVisible = true;
        if(component !== null){
            this.sidebarActiveComponent = component;
        }
    }

    hideSideBar = () =>{
        this.isSidebarVisible = false;
    }

    showWorkflows = () => {
        this.isWorkflowsVisible = true;
    }

    hideWorkflows = () =>{
        this.isWorkflowsVisible = false;
    }

    showReport = () => {
        this.isReportVisible = true;
    }

    hideReport = () =>{
        this.isReportVisible = false;
    }

    showUpdate = () => {
        this.isUpdateVisible = true;
    }

    hideUpdate = () =>{
        this.isUpdateVisible = false;
    }

    buildWorkspaceBranchList = (branches) =>{
        branches.forEach(branch=>{
            let branchChildren = branch.children;
          //  delete branch.children;
            let findBranchExitance = this.workspaceBranchList.find(wbranch=>wbranch.id === branch.id);
            if(findBranchExitance === undefined){
                this.workspaceBranchList.push(branch);
                if(branchChildren !== undefined){
                    this.buildWorkspaceBranchList(branchChildren);
                }
            }
            
        })
    }

    hexToRgb=(hex,opacity, important)=>{
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if(result){
            let rgb = {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
              }
            if(opacity){
                return `rgba(${rgb.r},${rgb.g},${rgb.b},${opacity}) ${important?`!important`:``}`;
            }
            else return `rgb(${rgb.r},${rgb.g},${rgb.b}) ${important?`!important`:``}`
        }
        else return null;
    }

    getFieldName = (fieldId) => {
        let field = Fields.find(f=>f.id === fieldId);
        if(!field){
            field = ContentFields.find(f=>f.id === fieldId);
        }

        if(field){
            return field.fieldname || field.label;
        }
        return fieldId;
    }



}

export default new GlobalStore();