import axios from 'axios';
import GlobalStore from '../store/GlobalStore';
const defaultVersion = 'v1';
let api_url = process.env.REACT_APP_API_URL;

//api_url = "http://localhost:5100/apis/";

class Httpservice{
  
    get = (url,token="",companyName=false,version=defaultVersion) => new Promise((resolve,reject)=>{
        var headers = {
            'Authorization': "Bearer "+token,
            'AuthToken':localStorage.getItem('auth_token'),
            'x-origin':GlobalStore.workspace_uid
        };
        var self = this;
        axios.get(api_url+'/'+version+'/'+url,{headers: headers})
        .then(function(response){
            resolve(response);
        })
        .catch(function(error){
            self.handleError(error);
            reject(error)
        });
    })
    

    post = (url,data,token,companyName=false,version=defaultVersion) => new Promise((resolve,reject)=>{
        var self = this;
        var headers = {
            'Authorization': "Bearer "+token,
            'AuthToken':localStorage.getItem('auth_token'),
            'x-origin':GlobalStore.workspace_uid
        };
       
        axios.post(api_url+'/'+version+'/'+url,data,{headers: headers})
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                self.handleError(error);
                reject(error);
            });
    })


    postFile = (url,data,token,version=defaultVersion) => new Promise((resolve,reject)=>{
        var self = this;
        axios.post(api_url+'/'+version+'/'+url,data,{headers: {'Authorization': "Bearer "+token, 'content-type': 'multipart/form-data' }})
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                self.handleError(error);
                reject(error);
            });
    })

    putFile = (url,data,type,contentDeposition=false)=>new Promise((resolve,reject)=>{
       var options = {
            headers: {
                'Content-Type': type,
              },
              onUploadProgress: function(progressEvent) {
                var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                GlobalStore.fileUploadPercentage=percentCompleted;
              }
        };

        if(contentDeposition){
            options['headers']['Content-Disposition'] = 'attachment';
        }
        
        axios.put(url,data,options).then(response=>{
            resolve(response)
        }).catch(error=>{
            reject(error);
        })
    })
   

    handleError = (error) =>{
        if(error && error.response && error.response.status === 406){
            localStorage.removeItem('auth_token');
            return window.location.href = '/authentication';
        }
        if(error && error.response && error.response.status === 402){
          return window.location.href = '/regret';
        }
    }
    

  
}

export default new Httpservice();