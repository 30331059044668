import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { observer } from 'mobx-react';
import GlobalStore from './store/GlobalStore';
import Loader from './components/common/Loader';


const ChooseWorkspace = lazy(()=> import('./components/Login/ChooseWorkspace'));
const Logout = lazy(()=> import('./components/Login/Logout'));
const ForgotPassword = lazy(()=> import('./components/Signup/forgotPassword'));
const Signup = lazy(()=> import('./components/Signup/signup'));
const AppSumoSignup = lazy(()=> import('./components/Signup/AppSumoSignup'));
const Login = lazy(()=> import('./components/Login/Login'));
const Introduction = lazy(()=> import('./components/PerformModule/templates/introduction'));
const Main = lazy(() => import('./components/Main'));
const Useractivation = lazy(() => import('./useractivation'));
const CreateWorkspace = lazy(() => import('./components/Signup/CreateWorkspace'));
const PreviewTemplate = lazy(() => import('./components/Template/Preview'));
const Workflows = lazy(() => import('./components/Dashboard/Workflows'));
const CompanyUsername = lazy(() => import('./components/Signup/CompanyUsername'));
const WorkspaceList = lazy(() => import('./components/Dashboard/WorkspaceList'));
const Account = lazy(() => import('./components/Account/Index'));
const VerifyWorkspace = lazy(() => import('./components/Login/VerifyWorkspace'));
const VerifyFinish = lazy(() => import('./components/Login/VerifyFinish'));
const AcceptWorkspace = lazy(() => import('./components/Workspace/AcceptWorkspaceInvitation'));
const PerformReport = lazy(() => import('./components/PerformModule/PerformReport'));
const VerifyEmail = lazy(() => import('./components/Signup/VerifyEmail'));
const NotverifyEmail = lazy(() => import('./components/Signup/NotverifyEmail'));
const PerformRequestAction = lazy(() => import('./components/singleChecklistModule/PerformRequestAction'));
const AppLogout = lazy(() => import('./components/Login/AppLogout'));
const TrialStart = lazy(()=> import('./components/Payment/TrialStart'));
const Regret = lazy(()=> import('./components/Payment/Regret'));
const PaymentMethod = lazy(()=> import('./components/Payment/StripePaymentMethodIntent'));
const ChangePlan = lazy(()=> import('./components/Payment/ChangePlan'));
const SecondAuth = lazy(()=> import('./components/Login/SecondAuth'))
const InviteWorkspace = lazy(() => import('./components/Signup/InviteWorkspace'));




const ProtectedRoute = ({ isAllowed, isVerified = true, ...props }) => {
    let search = window.location.search;
    return isAllowed
        ? isVerified ? 
        <Route {...props} /> : 
        <Redirect to={`/verify-email${search}`} />
        : <Redirect to="/login" />
}




@observer
class RouterModule extends Component {
    render() {
        return (
            <React.Fragment>
            {
                process.env.NODE_ENV && process.env.NODE_ENV === 'staging'?
                <span style={{position:"fixed", background:"#000", left:"50%", transform:"translateX(-50%)", color:"#FFF", fontSize:"11px", zIndex:"999999999999", padding:"0px 10px", textTransform:"uppercase"}}>Test Server</span>
                :null
            }
            
            <Router>
                <Suspense fallback={<Loader className="center-loader"/>}>
                    <Switch>
                        <Route exact path="/" component={()=><Login />} />
                        <Route exact path="/create-account" component={()=><Signup />} />
                        <Route exact path="/signup" component={()=><Signup />} />
                        <Route exact path="/appsumo" component={()=><AppSumoSignup />} />
                        <Route exact path="/appsumo/:code" component={()=><AppSumoSignup />} />
                        <Route exact path="/password/help" component={()=><ForgotPassword />} />
                        <Route exact path="/register" component={()=><Signup />} />
                        <Route exact path="/logout" component={()=><Logout />} />
                        <Route exact path="/app/logout" component={()=><AppLogout />} />
                        <Route exact path="/login" component={()=><Login/>} />
                        <Route exact path="/signin" component={()=><Login/> } />
                        <Redirect path="/my-tasks" to="/tasks" />
                        {/* <Route path="/editor" component={()=><TextEditor} /> */}
                        <Route exact path="/accept-invitation/:checklistid/:documentid/" component={()=><Useractivation/> } />
                        <Route exact path="/verify/:token" component={()=><VerifyWorkspace/>} />
                        <Route exact path="/verify" component={()=><VerifyFinish/> } />
                        <Route exact path="/preview/template/:templateID" component={()=><PreviewTemplate />} />
                        <Route exact path="/introduction" component={()=><Introduction />} />
                        <Route exact path="/workspace/choose" component={()=><ChooseWorkspace />} />
                        <Route exact path="/workspace/accept/:ucrId" component={AcceptWorkspace} />

                        <ProtectedRoute exact path="/workspaces/create" isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} component={()=><CreateWorkspace />} />
                        <ProtectedRoute exact path="/workspace/:companyID/name" isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} component={()=><CompanyUsername />} />
                        <ProtectedRoute exact path="/workspaces" isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} component={()=><WorkspaceList />} />
                        <ProtectedRoute exact path="/home" isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} component={()=><Main />} />
                        <ProtectedRoute exact path=" /checklist/:slug" isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} component={()=><Workflows />} />
                        <ProtectedRoute exact path="/checklist/:slug/:option" isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} component={()=><Workflows />} />

                        {/* <ProtectedRoute exact path="/workflow/:slug/tasks/edit" isAllowed={GlobalStore.isLoggedIn} component={()=><TaskEditor} /> */}
                        <ProtectedRoute path="/workflow" isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} component={()=><Main />} />
                        <ProtectedRoute path="/workflows" isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} component={()=><Main />} />
                        <ProtectedRoute path="/settings" isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} component={()=><Main />} />
                        <ProtectedRoute path="/settings/:slug" isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} component={()=><Main />} />
                        
                        <ProtectedRoute exact path="/account/:slug" isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} component={(props)=><Account {...props} />} />
                        <ProtectedRoute exact path="/account" isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} component={(props)=><Account {...props} />} />
                        
                        <ProtectedRoute path="/product/update" isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} component={()=><Main />} />

                        <ProtectedRoute exact path="/perform/:checklistid/:slug" isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} component={()=><Main />} />
                        <ProtectedRoute exact path="/perform/:checklistid/:slug/:tab" isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} component={()=><Main />} />
                        {/* <ProtectedRoute exact path="/perform/:checklistid/:slug/:introduction" isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} component={()=><Main />} /> */}
                        <ProtectedRoute exact path="/perform/:checklistid/:slug/:tab/:taskid" isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} component={()=><Main />} />
                        <ProtectedRoute exact path="/perform/:checklistid/:slug/:tab/:tabId/:tab2/:tab2Id" isAllowed={GlobalStore.isLoggedIn} component={()=><Main />} />
                        {/* <ProtectedRoute exact path="/perform2/:checklistid/:slug" isAllowed={GlobalStore.isLoggedIn} component={()=><TaskPerform} /> */}
                        <ProtectedRoute exact path="/billing" isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} component={()=><Main />} />
                        {/* <ProtectedRoute exact path="/account" isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} component={()=><AccountProfile />} /> */}
                        <ProtectedRoute exact path="/dashboard" component={()=><Main />} isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} />
                        <ProtectedRoute exact path="/performs" component={()=><Main />} isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} />
                        <ProtectedRoute exact path="/recent" component={()=><Main />} isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} />
                        <ProtectedRoute exact path="/Report" component={()=><PerformReport />} isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} />
                        <ProtectedRoute exact path="/verify-email" isAllowed={GlobalStore.isLoggedIn} component={()=><VerifyEmail />} />
                        <ProtectedRoute exact path="/not/verify-email" component={()=><NotverifyEmail />} isAllowed={GlobalStore.isLoggedIn} />
                        <ProtectedRoute exact path="/perform/request/:performId/:requestId" component={()=><PerformRequestAction />} isAllowed={GlobalStore.isLoggedIn} />
                        <ProtectedRoute exact path="/trial/start" component={()=><TrialStart />} isAllowed={GlobalStore.isLoggedIn} />
                        <ProtectedRoute exact path="/regret" component={()=><Regret />} isAllowed={GlobalStore.isLoggedIn} />
                        <ProtectedRoute exact path="/payment-method" component={()=><PaymentMethod />} isAllowed={GlobalStore.isLoggedIn} />
                        <ProtectedRoute exact path="/payment/plan/change" component={()=><ChangePlan />} isAllowed={GlobalStore.isLoggedIn} />

                        <ProtectedRoute exact path="/tasks" component={()=><Main />} isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} />
                        <ProtectedRoute exact path="/report/:id" component={()=><Main />} isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} />

                        <ProtectedRoute exact path="/workspace/invite" isAllowed={GlobalStore.isLoggedIn} isVerified={GlobalStore.isEmailVerified} component={()=><InviteWorkspace />} />

                        <ProtectedRoute exact path="/authentication" isAllowed={GlobalStore.isLoggedIn} component={()=><SecondAuth />} />
                        <ProtectedRoute path="/download" isAllowed={GlobalStore.isLoggedIn} component={()=><Main />} />

                     </Switch>
                </Suspense>
            </Router>
            </React.Fragment>
        );
    }
}

export default RouterModule;