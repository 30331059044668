import React, { Component } from 'react';

class Loader extends Component {
    render() {
        return (
            <div className={this.props.className}>
                <img alt="Loading..." src="/images/loader.svg" />
            </div>
        );
    }
}

export default Loader;