import { observable } from "mobx";
import FirebaseService from "../service/FirebaseService";
import HttpService from "../service/HttpService";
import { message, Modal } from "antd";
import Constants from "../Constants";

class AccountStore {
    @observable profileData = {};
    @observable isDPloaderVisible = false;
    @observable isLoading = false;

    fetchProfile = () => new Promise(resolve=>{
        FirebaseService.getFirebaseAccessToken().then(token=>{
            HttpService.get('workspace/company/member/profile?workspaceInf=false',token)
            .then(res=>{
                if(res.data.status===true){
                    this.profileData = res.data.result;
                    resolve();
                }
                else{
                    message.error(res.data.message,0.8);
                }
            })
        })
        .catch(error=>{message.error(error,0.9)});
    })


    saveImage(e){
        var fileObject = e.target.files[0];
        if(!fileObject){
            return;
        }
        
        if(fileObject && fileObject.size > 3145728){
            Modal.error({title:"File size should not exceed 3MB"});
            return;
        }

        if(fileObject && fileObject.type && fileObject.type.indexOf('image/') < 0){
            Modal.error({title:"Please upload image"});
            return;
        }

        var fileInf = {
            name:fileObject.name,
            type:fileObject.type,
            request_type:'profilephoto'
        }
        this.isDPloaderVisible = true;
        FirebaseService.getFirebaseAccessToken().then(token=>{
            HttpService.post('upload/displayphoto',fileInf,token).then(res=>{
                if(res.data.success){
                    var signedURI = res.data.data.returnData.signedRequest;
                    HttpService.putFile(signedURI,fileObject,fileInf.type).then(result=>{
                        if(result.status===200){
                            this.isDPloaderVisible = false;
                            this.profileData['profile_photo']=res.data.data.returnData.fileName;
                           // this.saveProfile();
                        }
                    }) 
                }
            })
        })   
    }

    saveProfile = () => new Promise((resolve,reject)=>{
        if(this.profileData.name!=="" || this.profileData.name!==null){
            FirebaseService.getFirebaseAccessToken().then(token=>{
                let body = {
                    name:this.profileData.name,
                    nick_name:this.profileData.nick_name,
                    gender:this.profileData.gender,
                    dob:this.profileData.dob,
                    country:this.profileData.country,
                    timezone:this.profileData.timezone,
                    profile_photo:this.profileData.profile_photo || undefined
                }
                HttpService.post('user/profile/update',body,token).then(res=>{
                    this.isLoading = false;
                    if(res.data.status){
                        Modal.success({title:"Your profile is updated successfully"});
                        resolve();
                    }
                    else{
                        message.error(res.data.message || "Error Occured!",0.8);
                        reject();
                    }
                })
            })
        }
        else{
            message.warning("Profile name can't be empty!",0.8);
            reject();
        }
    })


    saveDateTimeSettings = () => new Promise((resolve,reject)=>{
        FirebaseService.getFirebaseAccessToken().then(token=>{
            let body = {
                timezone:this.profileData.timezone,
                dateFormat:this.profileData.dateFormat || Constants.allowedDateFormats[0],
                timeFormat:this.profileData.timeFormat || Constants.allowedTimeFormats[0],
                additionalTimezones: this.profileData.additionalTimezones || [],
                showTimeDiffOnHover: this.profileData.showTimeDiffOnHover || false
            }
            HttpService.post('user/datetime/update',body,token).then(res=>{
                this.isLoading = false;
                if(res.data.status){
                    Modal.success({title:"Your date and time settings is updated successfully"});
                    resolve();
                }
                else{
                    message.error(res.data.message || "Error Occured!",0.8);
                    reject();
                }
            })
        })
    })


    get2FAAuthenticators = () => new Promise((resolve,reject)=>{
        FirebaseService.getFirebaseAccessToken().then(token=>{
            HttpService.get(`user/2fa/authenticators`,token).then(res=>{
                resolve(res.data);
            })
        })
    })

    bypass2FA = (key) => new Promise((resolve,reject)=>{
        FirebaseService.getFirebaseAccessToken().then(token=>{
            HttpService.post(`user/2fa/authenticator/bypass`,{key},token).then(res=>{
                resolve(res.data);
            }).catch(err=>{
                reject(err && err.response && err.response.data);
            })
        })
    })

    delete2FAMethod = id => new Promise((resolve,reject)=>{
        FirebaseService.getFirebaseAccessToken().then(token=>{
            HttpService.post(`user/2fa/authenticator/delete`,{authenticator_id:id},token).then(res=>{
                resolve(res.data);
            }).catch(err=>{
                reject(err && err.response && err.response.data);
            })
        })
    })

    sendEmergencyKeyToEmail = () => new Promise((resolve,reject)=>{
        FirebaseService.getFirebaseAccessToken().then(token=>{
            HttpService.post(`user/2fa/emergency-key/email`,{},token).then(result=>{
                resolve();
            }).catch(err=>{
                reject((err && err.response && err.response.data) || "Error Occured")
            })
        })
    })

}

export default new AccountStore();