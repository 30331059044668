import React, { Component } from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import AccountMenu from './AccountMenu';

const {
    Header
  } = Layout;


class CommonHeader extends Component {
 
    render() {
        return (
            <Header className={this.props.className}>
                <div className="main-header">
                    <div className="logo-block">
                        <Link to="/home"><img alt='GoodFlow' src="/images/logo_v3.svg" height="30px"></img></Link>
                        {
                            process.env.NODE_ENV==='development'?(
                                <span style={{color:"red", fontSize:'12px'}}></span>
                            ):null
                        }
                    </div>
                
                    <div className="logout-menu">
                        <AccountMenu/>
                    </div>
                </div>    
        </Header>
        );
    }
}

export default CommonHeader;