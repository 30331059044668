import React from 'react';
import CommonHeader from '../common/Header';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false,error:null };
    }
  
    componentDidCatch(error, info) {
      this.setState({ hasError: true,error });
    }
  
    render() {
      if (this.state.hasError) {
        return <div className="error-boundary">
          <CommonHeader className="no-background" />
          <div className="base-error">
            <div className="img-block">
              <img src="/images/error-boundry-ist-img.png" alt="" />
            </div>
            <div className="content-text">
              <h2>Something's not right !!</h2>
              <p>This is not you. It’s us who broke the flow. We are sorry for your inconvenience. We will look into this crash report asap.</p>
            </div>
          </div>
        </div>;
      }
      return this.props.children;
    }
  }

  export default ErrorBoundary;